import logo from "./logo.svg";
import "./App.css";
import Home from "./Pages/Home";

function App() {
  return (
    <div className="App">
      <h1>Coming Soon</h1>
      <img src="images/wklogo.jpeg" width={300} height={300} />
      <div style={{ textAlign: "center", width: "100%" }}>
        <Home />
      </div>
    </div>
  );
}

export default App;
